<template>
  <div>
    <RoleHeader :data="roleHeader" @buttonClick="buttonClick"></RoleHeader>
    <MenuNav :data="menuNav"></MenuNav>
    <Contract ref="contract" :data="menuNav"></Contract>
  </div>
</template>
<script>
import RoleHeader from "../../components/RoleHeader";
import MenuNav from "../../components/MenuNav";
import Contract from "../../components/Contract";
export default {
  components: { RoleHeader, MenuNav, Contract },
  data() {
    return {
      roleHeader: {
        background: require("../../assets/images/painter/title.png"),
        rightButtons: [
          {
            img: require("../../assets/images/painter/order-management.png"),
            imgChecked: require("../../assets/images/painter/order-management-checked.png"),
            link: "/painter/order_management",
          },
          {
            img: require("../../assets/images/painter/daily-clock.png"),
            imgChecked: require("../../assets/images/painter/daily-clock-checked.png"),
            link: "/painter/daily_clock",
          },
          {
            img: require("../../assets/images/painter/cumulative-achievements.png"),
            imgChecked: require("../../assets/images/painter/cumulative-achievements-checked.png"),
            link: "/painter/cumulative_achievements",
          },
          {
            img: require("../../assets/images/painter/uploading.png"),
            imgChecked: require("../../assets/images/painter/uploading-checked.png"),
            link: "/painter/uploading",
          },
        ],
      },
      menuNav: {
        navList: [
          {
            link: "/painter/take_orders",
            img: require("../../assets/images/painter/take-orders.png"),
            imgChecked: require("../../assets/images/painter/take-orders-checked.png"),
          },
          {
            link: "/painter/communication",
            img: require("../../assets/images/painter/communication.png"),
            imgChecked: require("../../assets/images/painter/communication-checked.png"),
          },
          {
            link: "/painter/settlement",
            img: require("../../assets/images/painter/settlement.png"),
            imgChecked: require("../../assets/images/painter/settlement-checked.png"),
          },
          {
            link: "/painter/dividend",
            img: require("../../assets/images/painter/dividend.png"),
            imgChecked: require("../../assets/images/painter/dividend-checked.png"),
          },
          {
            link: "/painter/college",
            img: require("../../assets/images/painter/college.png"),
            imgChecked: require("../../assets/images/painter/college-checked.png"),
          },
          {
            link: "/painter/tool",
            img: require("../../assets/images/painter/tool.png"),
            imgChecked: require("../../assets/images/painter/tool-checked.png"),
          },
        ],
      },
    };
  },
  methods: {
    buttonClick() {},
  },
};
</script>
