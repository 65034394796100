<template>
  <div class="content" v-loading="loading" v-if="show">
    <div class="html-content" v-html="content" @input="goAnchor"></div>
    <div class="id-card">
      <el-upload class="upload-demo" ref="upload1" drag :action="uploadAction" :on-success="handleUploadSuccess1"
        :on-remove="handleUploadRemove1" :limit="1" multiple>
        <img :src="positive.fullurl" alt="" v-if="positive.fullurl" />
        <div v-else>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">身份证正面</div>
        </div>
      </el-upload>
      <el-upload class="upload-demo" ref="upload2" drag :action="uploadAction" :on-success="handleUploadSuccess2"
        :on-remove="handleUploadRemove2" :limit="1" multiple>
        <img :src="contrary.fullurl" alt="" v-if="contrary.fullurl" />
        <div v-else>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">身份证反面</div>
        </div>
      </el-upload>
    </div>
    <div class="button">
      <el-button type="primary" @click="submit" :disabled="disabled">提交协议</el-button>
    </div>
  </div>
</template>

<script>
function eventListener() {
  console.log(123);
}
console.log(document.getElementById("partyB_IDNumber"));
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      loading: false,
      content: "",
      time: "xxx", // 日期
      matchArr: [],
      uploadAction: "",
      positive: {},
      contrary: {},
      disabled: true
    };
  },
  mounted() {
    this.uploadAction = axios.defaults.baseURL + "api/common/upload";
  },
  methods: {
    handleUploadSuccess1(res) {
      this.positive = res.data;
    },
    handleUploadRemove1() {
      this.$refs.upload1.clearFiles();
      this.positive = {};
    },
    handleUploadSuccess2(res) {
      this.contrary = res.data;
    },
    handleUploadRemove2() {
      this.$refs.upload2.clearFiles();
      this.contrary = {};
    },
    // 获取是否签署合同
    getIsContract() {
      this.$api.contract.is_contract().then((res) => {
        if (res.data) {
          this.show = false;
          return;
        }
        this.getAnnouncement();
      });
    },
    goAnchor() {
      let DOM_value = []
      this.matchArr.forEach((item) => {
        let DOM = document.getElementById(item);
        if (!DOM.value) {
          DOM_value.push(DOM.value)
        }
      });
      this.disabled = DOM_value.length ? true : false
    },
    // 获取协议内容
    getAnnouncement() {
      this.show = true;
      this.loading = true;
      this.$api.general.announcement({ type: "contract" }).then((res) => {
        this.loading = false;
        this.matchArr = res.data.match(/(?<=\{)(.+?)(?=\})/g);
        let id_is_positive = this.matchArr.indexOf("id_is_positive");
        if (id_is_positive) {
          this.matchArr.splice(id_is_positive, 1);
        }
        let id_is_contrary = this.matchArr.indexOf("id_is_contrary");
        if (id_is_contrary) {
          this.matchArr.splice(id_is_contrary, 1);
        }
        let content = res.data;
        this.matchArr.forEach((item) => {
          let reg = new RegExp("{" + item + "}", "g");
          content = content.replace(reg, this.getInput(item));
        });
        this.content = content;
        eventListener()
      });
    },
    // 提交
    submit() {
      let submitArr = {};
      let isTrue = true;
      this.matchArr.forEach((item) => {
        let DOM = document.getElementById(item);
        DOM.style.borderColor = "none";
        if (!DOM.value) {
          DOM.style.borderColor = "red";
          isTrue = false;
        } else {
          DOM.style.borderColor = "#fff";
        }
        submitArr[item] = DOM.value;
      });
      if (!this.positive.url) {
        this.$message.error("请上传身份证正面");
        return;
      }
      if (!this.contrary.url) {
        this.$message.error("请上传身份证反面");
        return;
      }
      if (isTrue) {
        submitArr.id_is_positive = this.positive.url;
        submitArr.id_is_contrary = this.contrary.url;
        this.$api.contract.submit(submitArr).then(() => {
          this.show = false;
        });
      }
    },
    // 根据字段返回html
    getInput(item) {
      let html = "";
      switch (item) {
        case "time":
          html =
            "<input id=" +
            item +
            ' value="' +
            this.utils.dateFormat("YYYY-mm-dd", new Date()) +
            '" disabled></input>';
          break;

        default:
          html = "<input id=" + item + ' placeholder="请输入内容"></input>';
          break;
      }
      return html;
    },
  },
};
</script>

<style lang="less">
.el-upload-dragger {
  width: 20vw;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<style>
.html-content::-webkit-scrollbar {
  display: block;
  width: 16px;
  height: 16px;
  background-color: #F5F5F5;
}

.html-content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #00b6f9;
}
</style>
<style lang="less" scoped>
.content {
  text-align: left;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 10vh;
  width: 50%;
  transform: translateX(-50%);
  z-index: 999;
  border-radius: 10px;
  box-shadow: 0 0 10px #fff;
  padding: 1vh;

  .html-content {
    height: 40vh;
    overflow: auto;
  }

  .id-card {
    display: flex;
    box-sizing: border-box;

    .upload-demo {
      width: 50%;
      text-align: center;
    }
  }

  .button {
    width: 100%;
    text-align: right;
    padding-top: 1vw;
  }
}
</style>